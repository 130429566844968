import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { NextPage } from 'next'

import { useQuery } from '@apollo/client';
import { IS_LOGGED_IN_QUERY } from '../graphql/apollo.query';

import Router from 'next/router';

import { Loader } from 'semantic-ui-react';

const IndexPage: NextPage = () => {
  const { data } = useQuery(IS_LOGGED_IN_QUERY);

  useEffect(() => {
    if (data?.isLoggedIn)
      Router.push("/dashboard");
    else
      Router.push("/auth/login");
  });

  return (
    <Layout title="" forGuests forUsers={false}>
      {!data?.isLoggedIn && <Loader />}
    </Layout>
  )
}

export default IndexPage
